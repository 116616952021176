<script>
import appConfig from '@/app.config';
import Layout from '../../layouts/main';
import { mapGetters } from 'vuex';
export default {
    components: {
        Layout,
    },
    page: {
        title: '404 Error Page',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    computed: {
        ...mapGetters('auth', ['authenticated']),
    },
};
</script>
<template>
    <Layout>
        <div class="account-pages my-5 pt-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mb-5">
                            <h1 class="display-2 fw-bold text-primary">
                                4<i
                                    class="bx bx-buoy bx-spin text-primary display-3"></i
                                >4
                            </h1>
                            <h4 class="text-uppercase fw-bold">
                                {{ $t('sorry, page not found') }}
                            </h4>
                            <div class="mt-5 text-center" v-if="!authenticated">
                                <router-link
                                    to="/login"
                                    class="btn btn-primary">
                                    {{ $t('go to login') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
